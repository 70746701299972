<template>
  <header class="navbar" :class="{ open: isMenuOpen }">
    <a href="/" class="logo-container">
      <img :src="logo" alt="Company Logo" class="logo" />
    </a>
    <div class="title">Dun Central</div>
    <div class="hamburger" id="hamburger" @click="toggleMenu">
      &#9776;
    </div>
    <nav :class="{ open: isMenuOpen }">
      <ul>
        <li><router-link to="/" @click.exact="closeMenu">About</router-link></li>
        <li><router-link to="/Projects" @click.exact="closeMenu">Projects</router-link></li>
        <li><router-link to="/Games" @click.exact="closeMenu">Games</router-link></li>
        <li><a href="https://github.com/EricTangedal?tab=repositories" target="_blank" @click.exact="closeMenu">GitHub</a></li>
        <li><a href="https://www.linkedin.com/in/erictangedal/" target="_blank" @click.exact="closeMenu">LinkedIn</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isMenuOpen: false,
      logo: require('@/assets/logo.png'),
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 1rem;
  z-index: 1000;
}
.logo-container {
  display: flex;
  align-items: center;
}
.logo {
  height: 60px;
  margin: -10px 20px -10px 0;
}
.title {
  width: 100%;
  color: ghostwhite;
  font-family: "Roboto Thin", sans-serif;
  font-size: 32px;
  font-weight: bold;
}
.hamburger {
  display: none; /* Hide hamburger for larger screens */
  cursor: pointer;
}
nav {
  flex-grow: 1;
  text-align: right;
}
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}
nav li {
  margin-left: 5px;
}
nav a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}
nav a:hover {
  background-color: #555;
}

@media (max-width: 1024px) {
  .hamburger {
    display: block; /* Show hamburger for smaller screens */
    font-size: 200%;
    position: fixed;
    right: 2%;
  }
  .title {
    top: 12px;
    left: 50%;
    width: 100%;
    z-index: -1;
  }
  nav {
    display: none; /* Hide nav for smaller screens, use hamburger menu instead */
  }
  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }
  nav li {
    margin: 1rem 0;
  }
  .navbar.open .hamburger {
    position: fixed;
    z-index: 2000;
  }
  .navbar.open .hamburger {
    right: 40px;
    top: 40px;
    font-size: 400%;
  }
  nav.open .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 20px;
  }
  nav.open {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  }
  nav.open ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  nav.open li {
    margin: 25px auto;
  }
  nav.open a {
    color: white;
    text-decoration: none;
    font-size: 2.5rem;
  }
}
</style>
