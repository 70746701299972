<template>
    <section id="projects">
      <h2>Projects</h2>
      <hr>
      <div class="projects-container">
        <div v-for="project in projects" :key="project.name" class="project-card mb-4">
          <div class="card h-100">
            <img :src="project.image" class="card-img-top project-thumbnail" :alt="project.name">
            <div class="card-body">
              <h5 class="card-title">{{ project.name }}</h5>
              <p class="card-description">Description: {{ project.description }}</p>
              <p class="card-text">{{ project.type }}</p>
              <p class="card-text"><small class="text-muted">{{ project.date }} | {{ project.author }}</small></p>
              <a :href="project.link" class="btn btn-primary" target="_blank">View Project</a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

export default {
  name: 'ProjectsPage',
  data() {
    return {
      projects: []
    };
  },
  created() {
    this.fetchProjects();
  },
  methods: {
    async fetchProjects() {
      try {
        const response = await axios.get('/data/projects.csv');
        const csvData = response.data;
        Papa.parse(csvData, {
          header: true,
          complete: (results) => {
            this.projects = results.data;
          }
        });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    }
  }
};
</script>



<style scoped>
/* General Styles */
body {
  font-family: Arial, sans-serif;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 10px 8%;
}

/* Project Card */
.project-card {
  flex: 0 1 calc(33.333% - 20px);
  box-sizing: border-box;
}

/* Card */
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

/* Card Image */
.card-img-top {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

/* Card Body */
.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

/* Card Title */
.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #999;
}

/* Card Description */
.card-description {
  font-size: 1rem;  
  padding: 12px 0% 0 0%;
  color: #999;
}

/* Card Text */
.card-text {
  font-size: 1rem;
  color: #999;
  margin-bottom: 10px;
}

/* Button */
.btn-primary {
  align-self: flex-start;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 4px;
  font-family: "Source Code Pro Black",serif;
  color: lightblue;
  text-decoration: none;
}
</style>